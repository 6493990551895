import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, TextInput, Button, Group, Text, LoadingOverlay, Paper } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import ImageAnnotator from './ImageAnnotator';
import { captureScreenshot } from '../services/api';

interface FeedbackPageProps {}

const FeedbackPage: React.FC<FeedbackPageProps> = () => {
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLoadWebsite = useCallback(async (targetUrl: string) => {
    setLoading(true);
    setError(null);
    try {
      // Update URL parameter
      const cleanUrl = targetUrl.startsWith('http') ? targetUrl : `https://${targetUrl}`;
      navigate(`/feedback?url=${encodeURIComponent(cleanUrl)}`, { replace: true });

      // Capture screenshot
      const screenshotUrl = await captureScreenshot(cleanUrl);
      setScreenshot(screenshotUrl);

      notifications.show({
        title: 'Success',
        message: 'Website loaded successfully',
        color: 'green',
        icon: <IconCheck size="1.1rem" />,
      });
    } catch (error) {
      console.error('Error loading website:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to load website';
      setError(errorMessage);
      notifications.show({
        title: 'Error',
        message: errorMessage,
        color: 'red',
        icon: <IconX size="1.1rem" />,
      });
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlParam = params.get('url');
    if (urlParam) {
      setUrl(urlParam);
      handleLoadWebsite(urlParam);
    }
  }, [location, handleLoadWebsite]);

  const handleUrlSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!url.trim()) {
      notifications.show({
        title: 'Error',
        message: 'Please enter a URL',
        color: 'red',
        icon: <IconX size="1.1rem" />,
      });
      return;
    }
    await handleLoadWebsite(url);
  };

  return (
    <Container size="xl" py="xl">
      <Box pos="relative">
        <LoadingOverlay visible={loading} />
        
        <Paper p="md" withBorder mb="md">
          <form onSubmit={handleUrlSubmit}>
            <Group>
              <TextInput
                placeholder="Enter website URL (e.g., google.com)"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                style={{ flex: 1 }}
                size="md"
                error={error}
                disabled={loading}
              />
              <Button type="submit" size="md" loading={loading}>
                Load Website
              </Button>
            </Group>
          </form>
        </Paper>

        {screenshot ? (
          <Paper p="md" withBorder>
            <ImageAnnotator imageUrl={screenshot} />
          </Paper>
        ) : (
          <Text c="dimmed" ta="center" mt="xl">
            Enter a URL above to start annotating
          </Text>
        )}
      </Box>
    </Container>
  );
};

export default FeedbackPage;
