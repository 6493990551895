import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

export interface Content {
  id: string;
  url: string;
  created_at: string;
}

export interface Comment {
  id: string;
  content_id: string;
  content: string;
  x_coordinate: number;
  y_coordinate: number;
  created_at: string;
}

export interface Annotation {
  id?: string;
  content_id: string;
  tool: string;
  points: { x: number; y: number; }[];
  color: string;
  created_at?: string;
}

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const feedbackApi = {
  createContent: async (url: string): Promise<Content> => {
    const response = await api.post('/content', { url });
    return response.data;
  },

  getComments: async (contentId: string): Promise<Comment[]> => {
    const response = await api.get(`/content/${contentId}/comments`);
    return response.data;
  },

  addComment: async (contentId: string, comment: Partial<Comment>): Promise<Comment> => {
    const response = await api.post(`/content/${contentId}/comments`, comment);
    return response.data;
  },

  getScreenshot: async (contentId: string): Promise<string> => {
    const response = await api.get(`/content/${contentId}/screenshot`);
    return response.data.url;
  },

  saveAnnotations: async (contentId: string, annotations: Omit<Annotation, 'id' | 'content_id' | 'created_at'>[]): Promise<Annotation[]> => {
    const response = await api.post(`/content/${contentId}/annotations`, { annotations });
    return response.data;
  },

  getAnnotations: async (contentId: string): Promise<Annotation[]> => {
    const response = await api.get(`/content/${contentId}/annotations`);
    return response.data;
  },
};

export const getProxiedUrl = (url: string) => {
  // Remove protocol if present
  const cleanUrl = url.replace(/^(https?:\/\/)/, '');
  // Use localhost for development, otherwise use the environment variable
  const apiUrl = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:3001' 
    : process.env.REACT_APP_API_URL;
  return `${apiUrl}/proxy/${cleanUrl}`;
};

export const captureScreenshot = async (url: string, selector?: string): Promise<string> => {
  const apiUrl = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:3001' 
    : 'https://api.discussit.io';

  try {
    const response = await fetch(`${apiUrl}/screenshot/capture`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url, selector }),
    });

    if (!response.ok) {
      throw new Error('Failed to capture screenshot');
    }

    const data = await response.json();
    return `${apiUrl}${data.screenshot}`;
  } catch (error) {
    console.error('Screenshot capture error:', error);
    throw error;
  }
};
