import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Image as KonvaImage, Line, Circle } from 'react-konva';
import { Box, ColorPicker, Group, Button, TextInput, ActionIcon, Paper, Grid, ScrollArea } from '@mantine/core';
import { IconEraser, IconPencil, IconTrash } from '@tabler/icons-react';

interface Annotation {
  points: number[];
  color: string;
  tool: string;
  comment?: string;
}

interface ImageAnnotatorProps {
  imageUrl: string;
  contentId?: string;
  annotations?: Annotation[];
  onAnnotationsChange?: (annotations: Annotation[]) => void;
}

const ImageAnnotator: React.FC<ImageAnnotatorProps> = ({
  imageUrl,
  contentId,
  annotations = [],
  onAnnotationsChange = () => {},
}) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [tool, setTool] = useState<'pen' | 'eraser'>('pen');
  const [color, setColor] = useState('#FF0000');
  const [isDrawing, setIsDrawing] = useState(false);
  const [comment, setComment] = useState('');
  const [localAnnotations, setLocalAnnotations] = useState<Annotation[]>(annotations);
  const stageRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imageUrl) return;

    const img = new window.Image();
    img.crossOrigin = 'anonymous';
    img.src = imageUrl;
    img.onload = () => {
      setImage(img);
      
      // Calculate image dimensions
      const maxWidth = window.innerWidth * 0.6;
      const maxHeight = window.innerHeight * 0.8;
      
      let finalWidth = img.width;
      let finalHeight = img.height;
      
      if (img.width > maxWidth || img.height > maxHeight) {
        const widthRatio = maxWidth / img.width;
        const heightRatio = maxHeight / img.height;
        const ratio = Math.min(widthRatio, heightRatio);
        
        finalWidth = img.width * ratio;
        finalHeight = img.height * ratio;
      }
      
      setImageSize({ width: finalWidth, height: finalHeight });
    };
  }, [imageUrl]);

  useEffect(() => {
    if (containerRef.current && image) {
      const container = containerRef.current;
      const scale = Math.min(
        container.clientWidth / image.width,
        container.clientHeight / image.height
      );
      stageRef.current?.width(image.width * scale);
      stageRef.current?.height(image.height * scale);
      stageRef.current?.scale({ x: scale, y: scale });
    }
  }, [image, containerRef.current?.clientWidth, containerRef.current?.clientHeight]);

  const handleMouseDown = (e: any) => {
    setIsDrawing(true);
    const pos = e.target.getStage().getPointerPosition();
    const newAnnotation: Annotation = {
      points: [pos.x, pos.y],
      color,
      tool,
    };
    const newAnnotations = [...localAnnotations, newAnnotation];
    setLocalAnnotations(newAnnotations);
    onAnnotationsChange(newAnnotations);
  };

  const handleMouseMove = (e: any) => {
    if (!isDrawing) return;

    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    const lastAnnotation = localAnnotations[localAnnotations.length - 1];
    lastAnnotation.points = lastAnnotation.points.concat([point.x, point.y]);
    const newAnnotations = [...localAnnotations.slice(0, -1), lastAnnotation];
    setLocalAnnotations(newAnnotations);
    onAnnotationsChange(newAnnotations);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    if (localAnnotations.length > 0 && comment) {
      const lastAnnotation = localAnnotations[localAnnotations.length - 1];
      lastAnnotation.comment = comment;
      setComment('');
      const newAnnotations = [...localAnnotations.slice(0, -1), lastAnnotation];
      setLocalAnnotations(newAnnotations);
      onAnnotationsChange(newAnnotations);
    }
  };

  const handleDeleteAnnotation = (index: number) => {
    const newAnnotations = localAnnotations.filter((_, i) => i !== index);
    setLocalAnnotations(newAnnotations);
    onAnnotationsChange(newAnnotations);
  };

  return (
    <Grid gutter="md">
      {/* Comments Section - Left Side */}
      <Grid.Col span={3}>
        <Paper withBorder p="md" style={{ height: '80vh' }}>
          <ScrollArea h="100%">
            {localAnnotations.map((annotation, index) => (
              annotation.comment && (
                <Paper key={index} p="xs" withBorder>
                  <Group justify="apart">
                    <Box>
                      <div
                        style={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          backgroundColor: annotation.color,
                          display: 'inline-block',
                          marginRight: 8,
                        }}
                      />
                      {annotation.comment}
                    </Box>
                    <ActionIcon
                      color="red"
                      variant="subtle"
                      onClick={() => handleDeleteAnnotation(index)}
                    >
                      <IconTrash size={16} />
                    </ActionIcon>
                  </Group>
                </Paper>
              )
            ))}
          </ScrollArea>
        </Paper>
      </Grid.Col>

      {/* Image Section - Center */}
      <Grid.Col span={6}>
        <Paper withBorder p="md" style={{ 
          height: '80vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto'
        }}>
          <Box style={{
            maxWidth: '100%',
            maxHeight: '100%',
            width: imageSize.width,
            height: imageSize.height,
            position: 'relative'
          }}>
            <Box
              ref={containerRef}
              style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: '#f8f9fa',
                border: '1px solid #dee2e6',
                borderRadius: '4px',
              }}
            >
              {image && (
                <Stage
                  ref={stageRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onTouchStart={handleMouseDown}
                  onTouchMove={handleMouseMove}
                  onTouchEnd={handleMouseUp}
                >
                  <Layer>
                    <KonvaImage image={image} />
                    {localAnnotations.map((annotation, i) => (
                      <React.Fragment key={i}>
                        <Line
                          points={annotation.points}
                          stroke={annotation.color}
                          strokeWidth={annotation.tool === 'eraser' ? 20 : 5}
                          tension={0.5}
                          lineCap="round"
                          globalCompositeOperation={
                            annotation.tool === 'eraser' ? 'destination-out' : 'source-over'
                          }
                        />
                        {annotation.comment && (
                          <Circle
                            x={annotation.points[0]}
                            y={annotation.points[1]}
                            radius={8}
                            fill={annotation.color}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Layer>
                </Stage>
              )}
            </Box>
            <img
              ref={imageRef}
              src={imageUrl}
              alt="Content to annotate"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
            />
          </Box>
        </Paper>
      </Grid.Col>

      {/* Ads Section - Right Side */}
      <Grid.Col span={3}>
        <Paper withBorder p="md" style={{ height: '80vh' }}>
          <ScrollArea h="100%">
            {/* Ads will go here */}
            Advertisement Space
          </ScrollArea>
        </Paper>
      </Grid.Col>

      <Grid.Col span={12}>
        <Group>
          <Button
            variant={tool === 'pen' ? 'filled' : 'light'}
            onClick={() => setTool('pen')}
            leftSection={<IconPencil size={16} />}
          >
            Draw
          </Button>
          <Button
            variant={tool === 'eraser' ? 'filled' : 'light'}
            onClick={() => setTool('eraser')}
            leftSection={<IconEraser size={16} />}
          >
            Erase
          </Button>
          <ColorPicker
            format="hex"
            value={color}
            onChange={setColor}
            swatches={['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF']}
          />
        </Group>

        <Group align="flex-start">
          <TextInput
            placeholder="Add a comment to your annotation..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={{ flex: 1 }}
          />
        </Group>
      </Grid.Col>
    </Grid>
  );
};

export default ImageAnnotator;
