import React, { useState } from 'react';
import {
  Container,
  TextInput,
  Title,
  Text,
  Button,
  Paper,
  Group,
  Transition,
  Box,
  useMantineTheme,
  rem,
} from '@mantine/core';
import { IconArrowRight, IconLink, IconPhoto, IconVideo } from '@tabler/icons-react';

export const LandingPage: React.FC = () => {
  const [url, setUrl] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const theme = useMantineTheme();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!url) return;

    // Clean up URL
    let processedUrl = url.trim();
    if (!/^https?:\/\//i.test(processedUrl)) {
      processedUrl = 'https://' + processedUrl;
    }
    
    // Remove trailing slashes
    processedUrl = processedUrl.replace(/\/+$/, '');
    
    // Encode the URL and redirect
    const encodedUrl = encodeURIComponent(processedUrl);
    window.location.href = `/feedback?url=${encodedUrl}`;
  };

  return (
    <Container size="lg" py={120}>
      <Title
        style={(theme) => ({
          fontSize: rem(60),
          fontWeight: 900,
          letterSpacing: -1,
          textAlign: 'center',
          [`@media (max-width: ${theme.breakpoints.md})`]: {
            fontSize: rem(50),
          },
        })}
      >
        Collaborate with{' '}
        <Text
          component="span"
          inherit
          variant="gradient"
          gradient={{ from: 'blue', to: 'cyan' }}
        >
          visual feedback
        </Text>
      </Title>

      <Text
        color="dimmed"
        size="lg"
        mx="auto"
        mt="xl"
        style={{ maxWidth: '600px', textAlign: 'center' }}
      >
        Share feedback on any website, image, or video. Click anywhere, leave comments,
        and collaborate in real-time.
      </Text>

      <Paper
        component="form"
        onSubmit={handleSubmit}
        shadow="md"
        radius="lg"
        p="xl"
        withBorder
        mt={50}
        style={(theme) => ({
          backgroundColor: theme.white,
          transition: 'transform 200ms ease, box-shadow 200ms ease',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: theme.shadows.lg,
          },
        })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Group
          style={{ justifyContent: 'space-between' }}
          mb="md"
        >
          <Group gap="xs">
            <IconLink size={20} stroke={1.5} />
            <IconPhoto size={20} stroke={1.5} />
            <IconVideo size={20} stroke={1.5} />
          </Group>
          <Text size="sm" color="dimmed">
            Paste a URL or drop a file
          </Text>
        </Group>

        <Group gap={0} style={{ position: 'relative' }}>
          <TextInput
            placeholder="Enter website URL or drag & drop image/video"
            style={{ flex: 1 }}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            size="lg"
            rightSection={
              <Transition mounted={isHovered || url.length > 0} transition="slide-right">
                {(styles) => (
                  <Button
                    variant="gradient"
                    gradient={{ from: 'blue', to: 'cyan' }}
                    size="md"
                    radius="xl"
                    rightSection={<IconArrowRight size={20} />}
                    type="submit"
                    style={styles}
                  >
                    Start
                  </Button>
                )}
              </Transition>
            }
            rightSectionWidth={isHovered || url.length > 0 ? 120 : 0}
          />
        </Group>

        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: 'none',
            zIndex: 1,
            border: `2px dashed ${theme.colors.blue[4]}`,
            borderRadius: theme.radius.lg,
            opacity: 0,
            transition: 'opacity 200ms ease',
            '&.dragover': {
              opacity: 0.3,
            },
          }}
        />
      </Paper>

      <Text
        color="dimmed"
        size="sm"
        mt="xl"
        style={{ maxWidth: '400px', margin: '0 auto', textAlign: 'center' }}
      >
        Free to use • No signup required • Instant collaboration
      </Text>
    </Container>
  );
};
